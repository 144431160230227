import { Prop, Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import vuescroll from "vuescroll";

@Component({
  components: {
    vuescroll,
  },
})
export default class DfModalComponent extends Vue {
  @Prop({ type: String, default: "" })
  id!: string;
  @Prop({ type: String, default: "" })
  modalTitle!: string;
  @Prop({ type: String, default: "Ok" })
  closeButtonLabel!: string;
  @Prop({ type: String, default: "" })
  acceptButtonLabel!: string;
  @Prop({ type: String, default: "" })
  acceptButtonIcon!: string;
  @Prop({ type: Boolean, default: false })
  showAcceptButton!: boolean;
  @Prop({ type: Boolean, default: true })
  fixedHeight!: boolean;
  @Prop({ type: Boolean, default: false })
  fitHeight!: boolean;
  @Prop({ type: Boolean, default: false })
  fullscreen!: boolean;

  @Prop({ type: Boolean, default: false })
  large!: boolean;
  @Prop({ type: Boolean, default: false })
  startOpen!: boolean;
  @Prop({ type: Boolean, default: true })
  showFooter!: boolean;
  @Prop({ type: Boolean, default: true })
  closeOnExternalClick!: boolean;
  @Prop({ type: Boolean, default: true })
  showCloseButton!: boolean;
  @Prop({ type: Boolean, default: true })
  showHeader!: boolean;
  @Prop({ type: Boolean, default: false })
  customFooter!: boolean;
  @Prop({ type: Number, default: 50 })
  zIndex!: number;

  private open = false;

  created() {
    this.$root.$on("modules-modal.close", (id: string) => {
      if (this.id == id) {
        this.setOpen(false);
        this.$root.$el.closest("body").classList.remove("df-overflow-hidden");
      }
    });
    this.$root.$on("modules-modal.destroy", (id: string) => {
      if (this.id == id) {
        this.setOpen(false);
        this.$root.$el.closest("body").classList.remove("df-overflow-hidden");
      }
    });
    this.$root.$on("modules-modal.open", (id: string) => {
      if (this.id == id && this.open !== true) {
        this.setOpen(true);
        this.$root.$el.closest("body").classList.add("df-overflow-hidden");
      }
    });
    this.$root.$on("modules-modal.accept", (id: string) => {
      if (this.id == id) {
        this.accept();
      }
    });

    this.open = this.startOpen;
  }

  updated() {
    if (this.open && this.$el != null) {
      if (!this.fixedHeight) {
        this.setModalHeight();
      }
      this.inputFocus();
      this.$emit("modalUpdated");
    }
  }

  close() {
    this.$emit("close");
    this.$root.$emit("modules-modal.close", this.id);
  }

  layerClick() {
    if (this.closeOnExternalClick) {
      this.close();
    }
  }

  accept() {
    this.$emit("accept");
    this.close();
  }

  beforeDestroy() {
    this.$emit("destroy");
    this.$root.$emit("modules-modal.destroy", this.id);
  }

  private setOpen(open: boolean) {
    this.open = open;
  }

  private inputFocus() {
    const inputElement: HTMLInputElement | null = this.$el.querySelector(".smt-dam-autofocus-input");
    if (inputElement != null) {
      inputElement.focus();
    }
  }

  private setModalHeight() {
    const uiModal = <HTMLDivElement>this.$el.querySelector(".smt-dam-modules-modal");
    if (uiModal != null) {
      const uiModalHeader = <HTMLDivElement>uiModal.querySelector(".smt-dam-modules-modal-header");
      const uiModalBody = <HTMLDivElement>uiModal.querySelector(".smt-dam-modules-modal-body");
      const uiModalFooter = <HTMLDivElement>uiModal.querySelector(".smt-dam-modules-modal-footer");
      const headerHeight = uiModalHeader == null ? 0 : uiModalHeader.offsetHeight;
      const bodyHeight = uiModalBody == null ? 0 : uiModalBody.offsetHeight;
      const footerHeight = uiModalFooter == null ? 0 : uiModalFooter.offsetHeight;
      uiModal.style.height = `${headerHeight + bodyHeight + footerHeight}px`;
    }
  }
}
